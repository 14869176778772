import { Icon } from '@iconify/react'
import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import { getFiles } from 'API/user'
import axios from 'axios'
import { PageProps } from 'gatsby'
import { navigate } from 'gatsby-link'
import React, { useRef } from 'react'
import { useEffect } from 'react'
import { Dropdown, Col, Modal, Row } from 'react-bootstrap'
import { PromiseType } from 'utility-types'
import { addFileToCDN } from '../course/[id]/classwork/add/addFileToCDN'
import queryString from 'query-string'

const PdfImg = '/files/pdf.png'
const PdfIco = '/files/pdf-ico.png'
const WordImg = '/files/word.png'
const WordIco = '/files/word-ico.png'
const ExcelImg = '/files/excel.png'
const ExcelIco = '/files/excel-ico.png'
const PptImg = '/files/ppt.png'
const PptIco = '/files/ppt-ico.png'
const Image = '/files/image.png'
const ImageIco = '/files/image-ico.png'
const File = '/files/file.png'
const FileIco = '/files/file-ico.png'
const Folder = '/files/folder.png'
const FolderIco = '/files/folder-ico.png'

import './files.scss'

const Files: React.FC<PageProps> = ({ location: { search } }) => {
	const [file, setFile] = React.useState<File>()
	const [files, setFiles] = React.useState<PromiseType<ReturnType<typeof getFiles>>>()
	const [folders, setFolders] = React.useState<string[]>([])

	const [folderName, setFolderName] = React.useState('')

	const [selectedFiles, setSelectedFiles] = React.useState<string[]>([])

	const [currentFolder, setCurrentFolder] = React.useState<string>()

	const ref = useRef<HTMLInputElement>(null)
	const handleAddFile = () => {
		if (ref.current) {
			ref.current.click()
		}
	}
	const handleAddFolder = async () => {
		console.log('add folder')
		await handleApi(
			axios.post<any>(
				getUrl(`cdn/move_files_to_folder`),
				{
					files_uid: selectedFiles,
					parent_folder: !currentFolder ? folderName : null,
				},
				{
					...getDefaultOptions(),
					// responseType: 'arraybuffer',
				}
			)
		)
		window?.location?.reload()
	}

	const handleSelectedFiles = (file: string) => {
		// add to array if doesn't exist
		if (!selectedFiles.includes(file)) {
			setSelectedFiles([...selectedFiles, file])
		}
		// remove from array if exists
		else {
			setSelectedFiles(selectedFiles.filter((f) => f !== file))
		}
	}

	const getFileImage = (type: string) => {
		switch (type) {
			case '.pdf':
				return [PdfImg, PdfIco]
			case '.xls':
				return [ExcelImg, ExcelIco]
			case '.doc':
				return [WordImg, WordIco]
			case '.docx':
				return [WordImg, WordIco]
			case '.pptx':
				return [PptImg, PptIco]
			case '.jpg':
				return [Image, ImageIco]
			case '.jpeg':
				return [Image, ImageIco]
			case '.png':
				return [Image, ImageIco]
			case 'folder':
				return [Folder, FolderIco]
			default:
				return [File, FileIco]
		}
	}

	useEffect(() => {
		const run = async () => {
			const files = await getFiles()
			if (files) {
				setFiles(files)
				const filesWithFolders = files.filter((elem) => elem.parent_folder)
				const folderNames = new Set<string>()
				filesWithFolders.forEach((elem) => {
					if (elem.parent_folder) {
						folderNames.add(elem.parent_folder)
					}
				})
				setFolders(Array.from<string>(folderNames))
			}
		}
		run()
	}, [file])

	useEffect(() => {
		if (search) {
			const parsed = queryString.parse(search)
			if (parsed.folderName) {
				setCurrentFolder(parsed.folderName.toString())
			} else {
				setCurrentFolder(undefined)
			}
		} else {
			setCurrentFolder(undefined)
		}
	}, [search])

	return (
		<>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-8 order-lg-first order-last'>
						<div className='row'>
							{currentFolder && (
								<>
									<button onClick={() => navigate('/files/')} className='row btn btn-primary'>
										Go to parent directory
									</button>
								</>
							)}
						</div>
						<div className='row '>
							<div className='modal-Container col-9 ml-lg-auto mb-2'>
								<Modal show={false} className='modalclass'>
									<Modal.Header>
										<Col xs={2} lg={1}>
											<button
												type='button'
												className='close float-left'
												data-dismiss='modal'
												aria-label='Close'
												onClick={() => {
													// navigate('../..')
												}}
											>
												<span aria-hidden='true'>&times;</span>
											</button>
										</Col>
										<Col xs={5} lg={8}>
											<h5 className='modal-title float-left'>active_file.name</h5>
										</Col>
									</Modal.Header>
									<Modal.Body>
										<Row className='mt-3 mb-5 mx-1'>
											{/* {asset.token && ( */}
											<iframe
												style={{ width: '100%', height: '25rem' }}
												src={
													'http://localhost:8080/asset/resource_attachment?resource_id=user2-53&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXNvdXJjZV9pZCI6InVzZXIyLTUzIiwiaWF0IjoxNjI5OTI0NDE1LCJleHAiOjE2Mjk5MjgwMTV9.T2QipGHu_no91LB4cdRAr_KBuvXiepqVkPWZ8PPF7ts'
													// asset.doc
													// 	? `https://view.officeapps.live.com/op/embed.aspx?src=${getUrl(
													// 			`asset/assignment_attachment?assignment_id=${assignment_id}&token=${asset.token}`
													// 	  )}`
													// 	: getUrl(
													// 			`asset/assignment_attachment?assignment_id=${assignment_id}&token=${asset.token}`
													// 	  )
												}
												frameBorder='0'
											></iframe>
											{/* )} */}
										</Row>
									</Modal.Body>
								</Modal>
							</div>

							{!currentFolder &&
								folders.map((elem) => {
									const [image, icon] = getFileImage('folder')
									return (
										<div onClick={() => navigate(`/files/?folderName=${elem}`)} key={elem} className='col-lg-4 col-md-6 col-12'>
											<div className='card fileCard my-2 mx-auto'>
												<div className='card-header fileCardHeader'>
													<input checked={false} type='checkbox' name='' id='' disabled />

													<img src={image} className='card-img-top fileImg' alt='...' />
												</div>
												<div className='card-body fileCardBody'>
													<div className='row align-items-center'>
														<div className='col-2'>
															<img src={icon} className='iconImg' alt='' />
														</div>
														<div className='col-8'>
															<div className='h6 mt-2 fileName'>{elem}</div>
														</div>
														<div className='col-2'>{/* <span className='float-right'>{DropDownData(file)}</span> */}</div>
													</div>
												</div>
											</div>
										</div>
									)
								})}
							{files &&
								files
									.filter((elem) => {
										if (currentFolder) {
											return elem.parent_folder === currentFolder
										} else {
											return !!!elem.parent_folder
										}
									})
									.map((file) => {
										const [image, icon] = getFileImage(file.file_extension)
										return (
											<>
												<div className=' col-lg-4 col-md-6 col-12'>
													<div className='card fileCard my-2 mx-auto'>
														<div
															onClick={() => {
																handleSelectedFiles(file.uid)
															}}
															className='card-header fileCardHeader'
														>
															<input checked={selectedFiles.includes(file.uid)} type='checkbox' name='' id='' />
															<img src={image} className='card-img-top fileImg' alt='...' />
														</div>
														<div className='card-body fileCardBody'>
															<div className='row align-items-center'>
																<div className='col-2'>
																	<img src={icon} className='iconImg' alt='' />
																</div>
																<div className='col-8'>
																	<div className='h6 mt-2 fileName'>{file.name}</div>
																</div>
																<div className='col-2'>
																	<span className='float-right'>{DropDownData(file)}</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</>
										)
									})}
						</div>
					</div>
					<div className=' col-lg-4 col-md-6 col-12'>
						<div className='card'>
							<form onSubmit={(e) => e.preventDefault()}>
								<div className='card-header bg-white'>
									<div className='workStatus my-3'>
										<span className='h4'>{}</span>
									</div>
									<input
										onChange={async (e) => {
											if (e.target.files?.[0]) {
												await addFileToCDN({
													file: e.target.files[0],
													name: e.target.files[0].name,
												})
												setFile(e.target.files[0])
											}
										}}
										ref={ref}
										type='file'
										name='file'
										hidden
										id=''
									/>
								</div>

								<div className='card-body'>
									<button onClick={handleAddFile} type='submit' className='btn btn-primary btn-block'>
										Add File
									</button>
								</div>

								{selectedFiles.length > 0 && (
									<div className='card-body'>
										{!currentFolder && (
											<div className='input-field'>
												<input
													type='text'
													placeholder='Folder Name'
													name='folder_name'
													onChange={(e) => {
														setFolderName(e.target.value)
													}}
													value={folderName}
													id=''
												/>
											</div>
										)}
										<button onClick={handleAddFolder} type='submit' className='btn btn-primary btn-block'>
											{!currentFolder ? 'Move to Folder' : 'Move to Parent'}
										</button>
									</div>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
		// <div>
		// 	<div className='flex'>
		// 		<div onClick={handleClick} className='btn tile'>
		// 			Add File
		// 			<input
		// 				onChange={async (e) => {
		// 					if (e.target.files?.[0]) {
		// 						await addFileToCDN({
		// 							file: e.target.files[0],
		// 							name: 'anyname',
		// 						})
		// 						setFile(e.target.files[0])
		// 					}
		// 				}}
		// 				ref={ref}
		// 				type='file'
		// 				name='file'
		// 				hidden
		// 				id=''
		// 			/>
		// 		</div>
		// 	</div>
		// 	<div className='p2'>
		// 		<h2 className='py-2 '>Files</h2>
		// 		<table className='table'>
		// 			<thead>
		// 				<tr>
		// 					<th scope='col'>#</th>
		// 					<th scope='col'>Name</th>
		// 					<th scope='col'>Added At</th>
		// 					<th scope='col'>Download</th>
		// 				</tr>
		// 			</thead>
		// 			<tbody>
		// 				{files?.map((elem, index) => {
		// 					return (
		// 						<>
		// 							<tr>
		// 								<th scope='row'>{index + 1}</th>
		// 								<td>{elem.name}</td>
		// 								<td>{new Date(elem.createdAt).toLocaleString()}</td>
		// 								<td>
		// 									<a
		// 										href=''
		// 										onClick={async (e) => {
		// 											e.preventDefault()
		// 											const data = await handleApi(
		// 												axios.get<any>(getUrl(`cdn/get_file?uid=${elem?.uid}`), {
		// 													...getDefaultOptions(),
		// 													responseType: 'arraybuffer',
		// 												})
		// 											)
		// 											if (window !== undefined && data) {
		// 												const url = window.URL.createObjectURL(new Blob([data]))

		// 												const link = document.createElement('a')
		// 												link.href = url
		// 												link.setAttribute('download', elem?.original_name ?? '')
		// 												document.body.appendChild(link)
		// 												link.click()
		// 											}
		// 										}}
		// 									>
		// 										Download
		// 									</a>
		// 								</td>
		// 							</tr>
		// 						</>
		// 					)
		// 				})}
		// 			</tbody>
		// 		</table>
		// 	</div>
		// </div>
	)
}

export default Files

function DropDownData(file: {
	name: string
	createdAt: string
	uid: string
	original_name: string // asset.doc
	// asset.doc
	// 	? `https://view.officeapps.live.com/op/embed.aspx?src=${getUrl(
	// 			`asset/assignment_attachment?assignment_id=${assignment_id}&token=${asset.token}`
	// 	  )}`
	// 	: getUrl(
	// 			`asset/assignment_attachment?assignment_id=${assignment_id}&token=${asset.token}`
	// 	  )
	file_extension: string // 	? `https://view.officeapps.live.com/op/embed.aspx?src=${getUrl(
}) {
	return (
		<Dropdown>
			<Dropdown.Toggle variant='white' className='no-hover no-shadow fileActions'>
				<Icon icon='bx:bx-dots-vertical-rounded' className='bx-sm float-right'></Icon>
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item
					onClick={async (e) => {
						e.preventDefault()

						const data = await handleApi(
							axios.get<any>(getUrl(`cdn/get_file?uid=${file.uid}`), {
								...getDefaultOptions(),
								responseType: 'arraybuffer',
							})
						)
						if (window !== undefined && data) {
							const url = window.URL.createObjectURL(new Blob([data]))

							const link = document.createElement('a')
							link.href = url
							link.setAttribute('download', file?.original_name ?? '')
							document.body.appendChild(link)
							link.click()
						}
					}}
				>
					<span>
						<Icon icon='bx:bxs-download' />
					</span>
					<span className='ml-2'>Download</span>
				</Dropdown.Item>
				<Dropdown.Item
					href=''
					onClick={async (e) => {
						e.preventDefault()
						alert(`Are you sure you want to delete file ${file.original_name} ?`)

						handleApi(axios.post(getUrl(`cdn/delete_file`), { file }, getDefaultOptions()))
						window?.location?.reload()
					}}
				>
					<span>
						<Icon icon='bx:bx-trash'></Icon>
					</span>
					<span className='ml-2'>Delete</span>
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}
