import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
// eslint-disable-next-line
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FormData = require('form-data')

export async function addFileToCDN(state: any) {
	const data = new FormData()
	console.log({ state })
	data.append('file', state['file'])
	data.append('name', state['name'])

	const resp = await handleApi<{ uid: string; _id: string }>(
		axios.post(getUrl('cdn/'), data, {
			headers: {
				'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
				authorization: localStorage.getItem('token'),
			},
		})
	)
	return resp
}

export async function addFileToCDNForPublic(state: any) {
	const data = new FormData()
	console.log({ state })
	data.append('file', state['file'])
	data.append('name', state['name'])
	data.append('public', state['public'])

	const resp = await handleApi<{ uid: string; _id: string }>(
		axios.post(getUrl('cdn/'), data, {
			headers: {
				'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
				authorization: localStorage.getItem('token'),
			},
		})
	)
	return resp
}
export async function addFileToCDNForProctorExam(state: any) {
	const data = new FormData()
	console.log({ state })
	data.append('file', state['file'])
	data.append('name', state['name'])

	const resp = await handleApi<{ uid: string; _id: string }>(
		axios.post(getUrl('cdn/photo_proctor_exam'), data, {
			headers: {
				'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
				authorization: localStorage.getItem('token'),
			},
		})
	)
	return resp
}
